




































































import {
  defineComponent,
  onMounted,
  onBeforeUnmount
} from "@vue/composition-api"

import JeopardAiLoadingAnimation from "./components/JeopardAiLoadingAnimation.vue"

import useIsViewerHostLike from "./use/useIsViewerHostLike"
import useJeopardAiMain from "./use/useJeopardAiMain"

export default defineComponent({
  name: "JeopardAI",
  components: {
    JeopardAiLoadingAnimation,
    JeopardAiCategories: () => import("./components/JeopardAiCategories.vue"),
    JeopardAiQuestions: () => import("./components/JeopardAiQuestions.vue"),
    JeopardAiQuestion: () => import("./components/JeopardAiQuestion.vue"),
    JeopardAiFinaleCategory: () =>
      import("./components/JeopardAiFinaleCategory.vue")
  },
  setup() {
    const {
      handleKeyPress,
      AnswersMode,
      finale,
      setCategory,
      question,
      working,
      questionId,
      questions,
      module,
      Module,
      state,
      category,
      drag,
      round,
      prev: navigateToPreviusStep
    } = useJeopardAiMain()
    const { isViewerHostLike } = useIsViewerHostLike()

    const listeners: Function[] = []
    onMounted(() => {
      if (isViewerHostLike.value) {
        listeners.push(() =>
          document.removeEventListener("keydown", handleKeyPress)
        )
        document.addEventListener("keydown", handleKeyPress)
      }
    })

    onBeforeUnmount(() => {
      listeners.forEach(unlisten => unlisten())
    })

    return {
      navigateToPreviusStep,
      round,
      drag,
      isViewerHostLike,
      AnswersMode,
      finale,
      setCategory,
      question,
      working,
      questionId,
      questions,
      module,
      Module,
      state,
      category
    }
  }
})
