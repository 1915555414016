var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jeopardai" },
    [
      _c(
        "transition",
        { attrs: { name: "jeopardai__transition", mode: "out-in" } },
        [
          _vm.working
            ? _c("JeopardAiLoadingAnimation", {
                staticClass: "jeopardai__loading",
                staticStyle: {
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translateX(-50%) translateY(-50%) scale(2)",
                  display: "block",
                },
              })
            : _vm.module === _vm.Module.CATEGORIES
            ? _c("JeopardAiCategories")
            : _vm.module === _vm.Module.QUESTIONS
            ? _c("JeopardAiQuestions", {
                key: _vm.round,
                staticClass: "jeopardai__questions mt-3 mb-3",
                attrs: {
                  questions: _vm.questions,
                  questionId: _vm.questionId,
                  category: _vm.category,
                },
                on: {
                  category: _vm.setCategory,
                  navigateToPreviusStep: _vm.navigateToPreviusStep,
                },
              })
            : _vm.module === _vm.Module.ANSWERS && _vm.question
            ? _c("JeopardAiQuestion", {
                key: _vm.question.id,
                staticClass: "pt-3 pb-3 jeopardai__answers",
                attrs: {
                  question: _vm.question,
                  state: _vm.state,
                  drag: _vm.drag,
                },
                on: { navigateToPreviusStep: _vm.navigateToPreviusStep },
              })
            : _vm._e(),
          _vm.isViewerHostLike === false &&
          _vm.module === _vm.Module.FINALE &&
          _vm.finale &&
          _vm.finale.category != null &&
          _vm.finale.verified == null
            ? _c("div", { staticClass: "jeopardai__final-question-waiting" }, [
                _c("div", { staticClass: "jeopardai__final-question-logo" }, [
                  _vm._v(" FINAL"),
                  _c("br"),
                  _vm._v("JEOPARD"),
                  _c("span", { staticClass: "contrast" }, [_vm._v("AI")]),
                ]),
                _c("div", { staticClass: "jeopardai__hint" }, [
                  _vm._v("We are verifying the final question"),
                ]),
              ])
            : _vm.module === _vm.Module.FINALE &&
              _vm.finale &&
              _vm.finale.category != null
            ? _c("JeopardAiQuestion", {
                key: _vm.Module.FINALE,
                staticClass: "pt-3 pb-3 jeopardai__answers",
                attrs: {
                  question: _vm.finale,
                  state: _vm.state,
                  mode: _vm.AnswersMode.FINALE,
                  drag: 0,
                },
              })
            : _vm.module === _vm.Module.FINALE && _vm.state.categories
            ? _c("JeopardAiFinaleCategory", {
                attrs: { options: _vm.state.categories },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }